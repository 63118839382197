.table_div_container {
    background-color: black;
    padding: 50px;
}

.table_div_container h1 {
    padding: 50px 0;
}

.table_div_container .tableed {
    border: 1px solid rgb(219, 238, 252);
    background-color: rgb(235, 238, 252);
}

.table_div_container .tableed .table_div_cell {
    background-color: rgb(233, 235, 246);
    color: rgb(34, 66, 136);
    font-weight: bolder;
    font-size: 22.75px;
    text-align: center;
}

.table_div_container .tableed .table_div_cell button {
    background-color: black;
    color: white;
    font-weight: bold;
    padding: 6px 20px;
    border-radius: 20px;
    text-transform: capitalize;
    margin-top: 10px;
}

.table_div_container .tableed .bgTableCell span {
    background-color: rgb(233, 235, 246);
    font-size: 18px !important;
}


.PlansInclude_div {
    background-color: black;
    color: white;
    padding: 60px 5px;
    text-align: center;
    font-family: "Roboto", sans-serif;

}

.PlansInclude_div h1 {
    color: white;
}

.PlansInclude_div h6 {
    color: white;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 50px;
}


.PlansInclude_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.PlansInclude_slidediv a {
    text-decoration: none;
}

.PlansInclude_div_slide {
    margin: 10px;
    width: 460px;
    height: 250px;
    border-radius: 14px 14px 10px 10px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;

}

.PlansInclude_div_slide img {
    height: 60px;
    /* border: 5px solid white; */
    width: 60px;
    margin: 10px 20px;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.PlansInclude_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 20px;
}

.PlansInclude_div_slide p {
    margin: 5px 20px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;

}



.HowtoAccess_div {
    background-color: black;
    color: white;
    padding: 60px 5px;
    text-align: center;
    font-family: "Roboto", sans-serif;

}

.HowtoAccess_div h1 {
    color: white;

}

.HowtoAccess_div h6 {
    color: white;

    font-weight: 600;
    font-size: 17px;
    margin-bottom: 50px;
}


.HowtoAccess_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.HowtoAccess_slidediv a {
    text-decoration: none;
}

.HowtoAccess_div_slide {
    margin: 10px;
    width: 360px;
    height: 220px;
    border-radius: 14px 14px 10px 10px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.HowtoAccess_div_slide img {
    height: 60px;
    /* border: 5px solid white; */
    width: 60px;
    margin: 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.HowtoAccess_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 20px;
}

.HowtoAccess_div_slide p {
    margin: 5px 20px;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;

}

@media screen and (max-width:767px) {
    .table_div_container {
        padding: 50px 15px;
    }

    .table_div_container h1 {
        font-size: 30px;
        padding-top: 0;
    }

    .PlansInclude_div_slide {
        width: 340px;
        height: 250px;

    }

    .PlansInclude_div_slide img {
        height: 60px;
        width: 60px;
    }

    .PlansInclude_div_slide h4 {
        margin: 10px;
        font-size: 32px;
    }

    .PlansInclude_div_slide p {
        margin: 5px 10px;

    }

    .HowtoAccess_div_slide {
        width: 340px;
        height: 250px;

    }

    .HowtoAccess_div_slide img {
        height: 60px;
        width: 60px;
    }

    .HowtoAccess_div_slide h4 {
        margin: 10px;
        font-size: 32px;
    }

    .HowtoAccess_div_slide p {
        margin: 5px 10px;

    }

}