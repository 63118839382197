.FeaturedProject_slide {
    padding: 10px 5px;
    font-family: "Roboto", sans-serif;

}

.FeaturedProject_slide a {
    text-decoration: none;
}


.project_fetaureddiv {
    margin: 20px 0px;
    border-radius: 6px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    padding: 0;
    width: 350px !important;
    background-color: #fff;
}

.project_fetaureddiv .teaminfo {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 5px 0;
}

.project_fetaureddiv .teaminfo p {
    font-size: 12px;
}

.project_fetaureddiv .teaminfo p a {
    display: flex;
    align-items: center;
    color: black;
}

.project_fetaureddiv .teaminfo p a span {
    margin: 0;
    color: #faaf00;
}

.project_fetaureddiv div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 1% 1% 0 0;
    margin: 0px 0 20px 0;

}

.project_fetaureddiv div h6 {
    font-weight: bold;
    padding: 0 20px;
    color: black;
    font-size: 16px;
    margin: 0 0 10px 0;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 1;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.project_fetaureddiv div p {
    padding: 0 20px;
    color: black;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 5px;

}

.project_fetaureddiv p span {
    color: gray;
    text-decoration: line-through;
    margin-left: 10px;
}

.project_fetaureddiv button {
    text-transform: capitalize;
    font-weight: bold;
}

.FeaturedProject_slide .slick-prev,
.FeaturedProject_slide .slick-next {
    background-color: rgb(0, 0, 0) !important;
    color: black !important;
    width: 30px !important;
    height: 40px !important;
    /* border-radius: 10px !important; */
    z-index: 66 !important;
    box-shadow: 2px 1px 6px rgb(125, 85, 85) !important;
}

.FeaturedProject_slide .slick-prev {
    border-radius: 0 6px 6px 0 !important;
    margin: 0 25px;
}

.FeaturedProject_slide .slick-next {
    border-radius: 6px 0 0 6px !important;
    margin: 0 26px;
}


@media screen and (min-width: 768px) and (max-width: 992px) {
    .FeaturedProject_slide {
        padding: 10px 5px;
    }

}


@media screen and (max-width:767px) {
    .FeaturedProject_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .FeaturedProject_slide h1 {
        font-size: 18px;
    }

    .project_fetaureddiv .teaminfo {
        display: block;

    }

    .project_fetaureddiv div img {
        width: 100%;
        height: 300px;

    }

    .FeaturedProject_slide .slick-prev {
        border-radius: 0 6px 6px 0 !important;
        margin: 0 15px;
    }
    
    .FeaturedProject_slide .slick-next {
        border-radius: 6px 0 0 6px !important;
        margin: 0 15px;
    }


}