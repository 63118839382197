.top_contactdiv{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2a2b;
  padding: 10px;
  font-family: "Roboto", sans-serif;
}
.top_contactdiv div ul{
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}
.top_contactdiv div ul li{
  display: flex;
  align-items: center;
  margin:0 10px;

}
.top_contactdiv div ul li a{
  margin: 0;
  display: flex;
  align-items: center;
  color:  white !important;
  text-decoration: none;

}
.top_contactdiv div ul li .fs5{
  margin-right: 6px;
  font-size: 20px;

}
.top_contactdiv div ul li .fs3{
  font-size: 24px;
}



.abarisDan_Header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #000000;
  backdrop-filter: blur(15px);
  box-shadow: 0 0 2px white;
  position: sticky !important;
  top: 0 !important;
  z-index: 9999;
  color: white;
  font-family: "Roboto", sans-serif;

}

.tanishkadiv_mobile {
  display: none;
}

.tanishkadiv_mobile button .fs-1textwhite {
  color: white;
  font-size: 38px;
}

.abarisDan_Header .logo {
  width: 8.5rem;
  margin-left: 34px;
  border-radius: 0.2rem;
}

.abarisDan_Header a {
  text-decoration: none;
  display: flex;
  align-items: center;

}
.abarisDan_Header .logo_divbox{
  /* width: 350px; */
  padding: 15px 0;

}

.abarisDan_Header .compnyName {
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  margin-left: 20px;
  color: white;
  line-height: 20px;
  font-style: italic;
}

.abarisDan_Header .abarisDan_liauto {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  margin:0px 0 0 0;
  padding: 6px 0;
}

.abarisDan_Header .abarisDan_liauto li {
  margin: 0 .6rem;
  font-size: 17px;
  font-weight: 500;

}

.abarisDan_Header .abarisDan_liauto li a {
  padding: 0.3rem 0.6rem;
  border-radius: 6px;
  text-decoration: none;
  color: white;
  font-size: 17px;
  font-weight: 500;

}
.abarisDan_Header .abarisDan_liauto li .loginbtnn {
  background: #4db5ff;

}

.abarisDan_Header .abarisDan_liauto li a:hover {
  background: rgba(142, 186, 241, 0.3);
  border-radius: 6px;
}

.abarisDan_Header .abarisDan_liauto li a.active {
  background: #4db5ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;

}

.abarisDan_Header .abarisDan_liauto li .buttonnn {
  background-color: #000000;
  border: 2px solid white;
  color: white;
  text-transform: capitalize;
  padding: 6px 20px;
  letter-spacing: 1px;
  font-size: 17px;
  font-weight: 500;
}

.open_list {
  position: relative;

}

.hidegroup {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 10;
  color: black;
  border-radius: 4px;
  margin-top: 1px;
  box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
  left: 1%;
  width: auto;
}

.open_list:hover .hidegroup {
  display: block;
}

.open_list .Ourboots_maindiv {
  list-style: none;
  color: #1c1b1b;
  border-radius: 4px;
  width: 240px;
  padding: 10px;

}
.open_list .Ourboots_maindiv h6 {
  margin: 0;
}

.open_list .Ourboots_maindiv h6 a{ 
  color: #1c1b1b;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
}
.open_list .Ourboots_maindiv h6 a:hover{
  background-color:#552667;
  color: white;
} 


@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }

  to {
    transform: translateY(0rem);
  }
}

@media only screen and (max-width: 767px) {
  .top_contactdiv{
    display: none;
  }

  .abarisDan_Header {
    z-index: 2;
    width: 100%;

  }
  .tanishkadiv_mobile {
    display: block;
    width: 100px;
  }

  .abarisDan_rightUL {
    display: none;
  }

  .abarisDan_Header .logo {
    width: 7rem;
    margin: 0 10px;
    border-radius: 0.5rem;
  }

  .abarisDan_Header .compnyName {
    /* display: none; */
    font-size: 14px;
    margin-left: 6px;
  }
}