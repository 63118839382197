.Boost_about_div .PaidCourseminiDiv {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.Boost_about_div .PaidCourseminiDiv p {
    margin: 0;
    padding: 0;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.Boost_about_div .PaidCourseminiDiv .choice_p {
    background-color: #a96624;
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 13px;
    margin-left: 10px;
}

.Boost_about_div .profile_nameDiv {
    display: flex;
    align-items: center;
}

.Boost_about_div .profile_nameDiv img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}



.Features_div {
    /* background-color: #f1e9e9; */
    padding: 60px 5px;
    text-align: center;
}

.Features_div h1 {
    color: black;
}

.Features_div h6 {
    color: black;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 50px;
}


.Features_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Features_slidediv a {
    text-decoration: none;
}

.Features_div_slide {
    margin: 10px;
    width: 270px;
    height: 160px;
    text-align: center;
    border-radius: 14px 14px 10px 10px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.Features_div_slide img {
    height: 40px;
    /* border: 5px solid white; */
    width: 40px;
    margin: 10px auto 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.Features_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 20px;
}

.Features_div_slide p {
    margin: 5px 20px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;

}

.AboutthisCourse {
    padding: 50px;
}

.AboutthisCourse h1 {
    font-weight: bold;
    text-align: center;
}

.AboutthisCourse h4 {
    letter-spacing: 1px;
    font-weight: bold;
    margin: 20px 0 10px 0;
}

.AboutthisCourse p {
    letter-spacing: 0.5px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;

}

.WhatwillyouLearn {
    padding: 50px;
}

.WhatwillyouLearn h1 {
    font-weight: bold;
    text-align: center;
}

.WhatwillyouLearn .Divmakedflex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}
.WhatwillyouLearn .divOutline1, .divOutline2, .divOutline3, .divOutline4{
    margin: 10px;
    border-radius: 10px;
    padding: 10px 20px;
    color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    width: 350px;
    height: 120px;
}
.WhatwillyouLearn .divOutline1 {
    background-color: #a96624;
}

.WhatwillyouLearn .divOutline2 {
    background-color: #24a938;
}

.WhatwillyouLearn .divOutline3 {
    background-color: #a92495;
}

.WhatwillyouLearn .divOutline4 {
    background-color: #245ea9;
}

.WhatwillyouLearn .iconn {
    font-size: 50px;
    margin-right: 10px;
}