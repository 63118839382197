#BoxId {
    margin: 0 !important;
    font-family: "Roboto", sans-serif;
}

#BoxId .custom-img img {
    height: 100vh !important;
    width: 100%;
    /* filter: brightness(0.6); */
    object-fit: cover;
}

#BoxId .swiper .swiper-slide {
    padding-top: 0 !important;

}

#BoxId .swiper-pagination .swiper-pagination-bullet {
    width: 16px !important;
    height: 6px !important;
    border-radius: 2px !important;
}

#BoxId .swiper-button-prev,
#BoxId .swiper-button-next {
    background-color: rgb(0, 0, 0) !important;
    padding: 10px;
    color: white !important;
    width: 28px !important;
    height: 28px !important;
    z-index: 66 !important;
    box-shadow: 2px 1px 6px rgb(125, 85, 85) !important;
    font-weight: bold;
}

#BoxId .swiper-button-prev {
    border-radius: 50% !important;
    margin: 0 25px;
}

#BoxId .swiper-button-next {
    border-radius: 50% !important;
    margin: 0 26px;
}

#BoxId {
    --swiper-navigation-size: 16px !important;
}

.DownloadAppDIV{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    color: white;
    padding: 20px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;

}
.DownloadAppDIV .butn{
    background-color: #fff;
    font-size: 12px;
    text-transform: capitalize;
    text-align: center;
    margin: 0 20px;
    font-family: "Roboto", sans-serif;
    border-radius: 40px;
    padding: 6px 10px;

}
.DownloadAppDIV .butn:hover{
    background-color: #fff;

}

.DownloadAppDIV .DnldTheApp{
    width: 40px;
    margin-right: 8px;
}
.DownloadAppDIV .butn span{
    font-weight: bold;
    font-size: 18px;
}
.Boost_about_div {
    text-align: center;
    padding: 20px 0;
    background-color: #020202;
    color: white;
    font-family: "Roboto", sans-serif;
}

.Boost_about_div h1 {
    color: white;
    font-weight: bold;
    padding-top: 20px;
    font-family: "Roboto", sans-serif;

}



.Boost_about_div h2 {
    color: white;
    font-weight: bold;
    margin-bottom: 60px;
}


.Boost_about_div_home {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;

}


.Boost_about_div_home .imggdiv {
    padding: 10px 40px;

}

.Boost_about_div_home .imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 400px;
    object-fit: contain;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    /* border: 20px solid #fff; */
    /* background-color: white; */

}

.Boost_about_div_home .textdiv {
    padding: 10px 40px;
    text-align: start;

}

.Boost_about_div_home .textdiv h6 {
    font-size: 40px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: rgb(25, 158, 132);
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.Boost_about_div_home .textdiv h5 {
    font-size: 30px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: rgb(25, 158, 132);
    margin-top: 20px;
}

.Boost_about_div_home .textdiv p {
    font-size: 17px;
    margin: 0px;
    letter-spacing: 1px;
    color: white;
    padding-top: 20px;
    text-align: justify;

}


.Boost_about_div_home .textdiv .morebtn {
    color: white;
    border: 1px solid white;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 30px;
    margin-top: 40px;
}

.Boost_about_div_home .textdiv .morebtn:hover {
    background: rgb(25, 158, 132);
    color: white;
    border: unset;

}



.TradingCompany_div {
    background-color: #0a0a0a;
    padding: 0px 5px;
    text-align: center;
}

.TradingCompany_div h1 {
    font-size: 40px;
    color: white;
    padding: 30px 0;
}

.Trading_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Trading_slide {
    margin: 2.5px;
    /* width: 100%; */
    text-align: justify;
    border-radius: 6px;
    background-color: white;
    padding: 1px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.Trading_slide h4 {
    margin: 20px;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
}

.Trading_slide p {
    margin: 20px;
    font-size: 17px;
    letter-spacing: 1px;
    text-align: center;
}


.allpackages_div {
    background-color: black;
    padding: 40px 5px;
    text-align: center;
}

.allpackages_div h1 {
    font-weight: bold;
    font-size: 40px;
    color: white;
    padding: 40px 0 0 0;
}

.packages_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.packages_life_Slider {
    margin: 20px;
    width: 350px;
    text-align: justify;
    border: 5px solid white;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 10px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.packages_life_Slider img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    /* border-radius: 4px 4px 0 0; */
}

.packages_life_Slider h4 {
    margin: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 24px;
    width: auto;
    color: black;
    text-align: center;
    /* Set the width of the element */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide the overflow text */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}


.PopularTours_div {
    background-color: black;
    padding: 40px 5px;
    text-align: center;
}

.PopularTours_div h1 {
    color: white;
}

.tour_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.tour_slide a {
    text-decoration: none;
}

.populartour_Slider {
    margin: 20px;
    width: 340px;
    text-align: justify;
    border-radius: 44px 44px 16px 16px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.populartour_Slider a {
    text-decoration: none;
    color: black;

}

.populartour_Slider img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    border-radius: 44px 44px 0 0;
}

.populartour_Slider h4 {
    margin: 10px;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #d67e27;
}

.populartour_Slider p {
    margin: 10px 20px;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 3;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.PopularTours_div .booknowbtn {
    background: linear-gradient(to right, #a77524, #d67e27);
    color: white;
    text-transform: capitalize;
    font-size: 17px;
    padding: 12px 30px;
    margin-top: 10px;
    border-radius: 0 0 8px 8px;
}

.PopularTours_div .butnn {
    background: linear-gradient(to right, #a77524, #d67e27);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}



.Civil_div {
    background-color: black;
    padding: 40px 5px;
    text-align: center;
}

.Civil_div h1 {
    color: white;
}

.Civil_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Civil_slide a {
    text-decoration: none;
}

.Civil_Slider {
    margin: 20px 10px;
    width: 270px;
    text-align: justify;
    border-radius: 44px 44px 16px 16px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.Civil_Slider a {
    text-decoration: none;
    color: black;

}

.Civil_Slider img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    border-radius: 44px 44px 0 0;
}

.Civil_Slider h4 {
    margin: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: #2764d6;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 1;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}

.Civil_Slider p {
    margin: 10px 20px;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 3;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.Civil_div .booknowbtn {
    background: linear-gradient(to right, #2764d6, #1f458c);
    color: white;
    text-transform: capitalize;
    font-size: 17px;
    padding: 12px 30px;
    margin-top: 10px;
    border-radius: 0 0 8px 8px;
}

.Civil_div .butnn {
    background: linear-gradient(to right, #2764d6, #1f458c);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}



.Sustainability_div {
    padding: 60px 5px;
    text-align: center;
    font-family: "Roboto", sans-serif;

}

.Sustainability_div h1 {
    color: black;
}

.Sustainability_div h6 {
    color: black;
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 50px;
}


.Sustainability_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Sustainability_slidediv a {
    text-decoration: none;
}

.Sustainability_div_slide {
    margin: 10px;
    width: 460px;
    height: 250px;
    text-align: center;
    border-radius: 14px 14px 10px 10px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.Sustainability_div_slide img {
    height: 60px;
    /* border: 5px solid white; */
    width: 60px;
    margin: 10px auto 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.Sustainability_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 32px;
}

.Sustainability_div_slide p {
    margin: 5px 20px;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 1px;

}

.logistic_clients {
    text-align: center;
    width: 100%;
    margin: auto;
    padding: 50px;
    font-family: "Roboto", sans-serif;
    background-color: #000000;

}

.logistic_clients h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 40px;
    color: white;
}

.logistic_clients p {
    font-size: 16px;
}

.Projects_topimg {
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 90% !important;
    padding: 20px;
    background-color: white;
}

.Projects_topimg img {
    height: 100px;
    width: 150px;
    object-fit: contain;
    margin: auto;
    align-items: center;
    border-radius: 4px;
    background-color: white;
}

.Projects_topimg p {
    font-weight: bold;
    margin-top: 20px;
}


.Satisfaction_div {
    background-color: #0a0a0a;
    padding: 60px 5px;
    text-align: center;
}

.Satisfaction_div h1 {
    color: white;
}


.Satisfaction_slidediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.Satisfaction_slidediv a {
    text-decoration: none;
}

.Satisfaction_div_slide {
    margin: 10px;
    width: 280px;
    height: 200px;
    text-align: center;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.Satisfaction_div_slide:hover {
    background-color: #d6c427;
}

.Satisfaction_div_slide img {
    height: 80px;
    /* border: 5px solid white; */
    width: 80px;
    margin: 10px auto 10px auto;
    object-fit: contain
        /* border-radius: 44px 44px 0 0; */
}

.Satisfaction_div_slide h4 {
    margin: 10px 20px 0 20px;
    font-weight: bold;
    font-size: 22px;
}

.Satisfaction_div_slide p {
    margin: 20px;
    font-size: 17px;
    letter-spacing: 1px;

}



/* testimonial */
#testimonials {
    background-color: #eee9e9;
    padding-bottom: 10px;
}

.testimonials_h2 {
    font-size: 40px;
    font-weight: bold;
    padding: 30px 0 30px 50px;
    color: #56594d;
}

.testimonials_container {
    width: 100%;
    padding-bottom: 1rem;
}

/* .testimonials_container .swiper-wrapper{
    width: 100%;
  }
  .testimonials_container .swiper-wrapper .swiper-slide{
    width: 50% !important;
    margin: 0 5px;
  } */


.testimonial {
    background: #ffffff;
    text-align: center;
    padding: 2rem;
    border-radius: 0.21rem;
    user-select: none;
    height: 300px !important;
    margin: 30px 0;
}

.testimonial:hover {
    background-color: #edacac;
}

.client_avatar img {
    width: 5rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid rgba(73, 67, 90, 0.4);
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    background-color: unset;
    padding: 0px;
}

.client_name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
}

.client_review {
    color: #000000;
    display: block;
    width: 80%;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
}


/* end testimonial */



.featured_projectweworkIn {
    margin: 0px 0;
    color: #1c1b1b;
    padding: 40px;
    font-family: "Roboto", sans-serif;

}

.logistic_topdiv {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.logistic_topdiv h1 {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* .tabsBox_Latest .tabs_latest{
} */

.tabsBox_Latest .tabs_latest .head_tabs {
    width: auto;
    display: flex;
    /* background-color: #ffffff; */
    border-radius: 8px;
    margin: 30px 0;
}

.mangetab1 {
    padding: 0.6rem 1.4rem;
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #1f1f20;
    text-align: start;
    width: auto;
    background-color: unset;
    border-bottom: 3px solid #efefef;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    /* box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1); */

}

.mangetab1:hover {
    color: #0F677A;
    background-color: white;
}

.latest_TabPanelDiv {
    width: 100%;
    color: #1f1f20;

}

.UpcomingFreeLiveWebinars {
    padding: 10px 5px;
    font-family: "Roboto", sans-serif;

}

.UpcomingFreeLiveWebinars a {
    text-decoration: none;
}

.UpcomingDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
}
.UpcomingDiv a{
     margin: 0 10px;
}

.project_LiveWebinars {
    margin: 20px 0px;
    border-radius: 6px;
    border: 5px solid rgb(255, 255, 255);
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    padding: 0;
    width: 350px !important;
    background-color: #fff;
}

.project_LiveWebinars div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 1% 1% 0 0;
    margin: 0px 0 20px 0;

}

.project_LiveWebinars div h6 {
    font-weight: bold;
    padding: 0 20px;
    color: black;
    font-size: 16px;
    margin: 0 0 10px 0;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 1;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.project_LiveWebinars div p {
    padding: 0 20px;
    color: black;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    display: flex;
    align-items: center;

}

.project_LiveWebinars button {
    text-transform: capitalize;
    font-weight: bold;
}


.section1 {
    background-color: #000000;
    min-height: 100vh;
    padding: 1rem 9% 6rem;
    font-family: "Roboto", sans-serif;

}

.contact-us-left {
    margin-top: 1px;
    font-size: 18px;
    color: white;
    text-align: center;
}


.contact-us .contact-us-form {
    padding: 50px 40px;
}

.contact-us .contact-us-form h2 {
    position: relative;
    font-size: 32px;
    color: white;
    font-weight: 600;
    line-height: 27px;
    text-transform: capitalize;
    margin-bottom: 12px;
    padding-bottom: 20px;
    text-align: left;
}

.contact-us .contact-us-form h2:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
    background: white;
}

.contact-us .contact-us-form p {
    font-size: 14px;
    color: white;
    font-weight: 400;
    text-align: left;
    margin-bottom: 50px;
}

.contact-us .form {
    margin-top: 30px;
}

.contact-us .form .form-group {
    margin-bottom: 22px;
}

.contact-us .form .form-group input {
    width: 100%;
    height: 50px;
    border: 1px solid black;
    text-transform: capitalize;
    padding: 0px 18px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
}

.contact-us .form .form-group textarea {
    width: 100%;
    height: 100px;
    border: 1px solid black;
    text-transform: capitalize;
    padding: 18px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
}

.section1 .btnbtnbgprimary {
    background-color: #2764d6;
    color: white;
}

.section1 .btnbtnbgprimary:hover {
    background-color: #1e4388;
    color: white;
}

@media screen and (max-width:767px) {
    #BoxId .custom-img img {
        height: 250px !important;
    }

    #BoxId .swiper-button-prev {
        margin: 0 10px;
    }

    #BoxId .swiper-button-next {
        margin: 0 10px;
    }

    .Boost_about_div h2 {
        font-size: 19px;
        margin: 20px;
    }

    .Boost_about_div_home .imggdiv {
        padding: 10px 40px;

    }

    .Boost_about_div_home .imggdiv img {
        height: 300px;
        border: 2px solid #fff;

    }

    .Boost_about_div_home .textdiv {
        padding: 10px 20px;
    }

    .Boost_about_div_home .textdiv h6 {
        font-size: 30px;
    }

    .Boost_about_div_home .textdiv p {
        padding-top: 10px;

    }

    .TradingCompany_div h1 {
        font-size: 26px;
        padding: 30px 10px;
    }

    .Trading_slide {
        margin: 10px 2.5px;
        width: 90%;

    }

    .allpackages_div h1 {
        font-size: 30px;
    }

    .PopularTours_div h1 {
        font-size: 26px;
    }

    .populartour_Slider img {
        height: 55vh;
    }


    .Sustainability_div_slide {
        width: 340px;
        height: 250px;

    }

    .Sustainability_div_slide img {
        height: 60px;
        width: 60px;
    }

    .Sustainability_div_slide h4 {
        margin: 10px;
        font-size: 32px;
    }

    .Sustainability_div_slide p {
        margin: 5px 10px;

    }

    .project_LiveWebinars div img {
        width: 100%;
        height: 300px;

    }

    .logistic_topdiv {
        width: 99%;
        padding: 30px 10px;
    }

    .logistic_topdiv h1 {
        font-size: 30px;
    }

    .featured_projectweworkIn {
        padding: 20px 0 0px 0px;
    }

    .logistic_clients {
        padding: 40px 0 0 0;
    }

    .logistic_clients h1 {
        font-size: 30px;
    }

    .Projects_topimg {
        margin: 10px;
        padding: 10px;
    }

    .our_partner .slick-slider button {
        display: none !important;
    }


    .mangetab1 {
        margin: 10px 20px;
    }


}